'use client';
import React, { useEffect } from 'react';
import { Badge, Box } from '@mui/material';
import clsx from 'clsx';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useIsPc } from '@/hooks/media-query';
import { useDrawer } from '@/hooks/useDrawer';
import { useFooterBudge } from '@/hooks/useFooterBadge';
import { useTenant } from '@/hooks/useTenant';
import { sleep } from '@/utils/tool';
import styles from './Footer.module.scss';

export default function Footer() {
  const { isDrawerOpen } = useDrawer();
  const isPc = useIsPc();

  const pathname = usePathname();
  const { currentTenant } = useTenant();
  const { budge, fetchFooterBudge, clearUnreadMention } = useFooterBudge(currentTenant?.id);

  useEffect(() => {
    const checkFooterBadge = async () => {
      await fetchFooterBudge();

      if (pathname === '/notification') {
        await sleep(1000);
        await clearUnreadMention();
      }
    };
    checkFooterBadge();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <Box
      sx={{ flexGrow: 1 }}
      className={clsx(
        styles.wrap,
        isPc && isDrawerOpen && styles.drawer_open,
        'flex items-center justify-around gap-2 fixed bottom-0 w-full text-white font-bold z-20'
      )}
    >
      <FooterIcon icon="project" text="ホーム" href="/" />
      <FooterIcon icon="task" text="Pj" href="/project" />
      <FooterIcon
        icon="notification"
        text="通知"
        href="/notification"
        badgeCount={budge.unreadMentionCount}
      />
      <FooterIcon icon="quick" text="QUICK" disabled />
    </Box>
  );
}

type FooterIconProps = {
  icon: string;
  text: string;
  href?: string;
  disabled?: boolean;
  badgeCount?: number;
};
const FooterIcon = (props: FooterIconProps) => {
  const { icon, text, href, disabled, badgeCount } = props;
  return (
    <>
      {href ? (
        <Link href={href}>
          <div className={clsx(styles.footerMenu, disabled && styles.disabled)}>
            <Badge
              badgeContent={badgeCount}
              color="secondary"
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              style={{ top: '8px' }}
              className={styles['MuiBadge-root']}
            >
              <div className={styles[`-${icon}`]} />
            </Badge>
            <div className={styles.buttonText}>{text}</div>
          </div>
        </Link>
      ) : (
        <div className={clsx(styles.footerMenu, disabled && styles.disabled)}>
          <div className={styles[`-${icon}`]} />
          <div className={styles.buttonText}>{text}</div>
        </div>
      )}
    </>
  );
};
