import React, { useState } from 'react';
import { sleep } from '@/utils/tool';
import styles from './modalOptions.module.scss';

type ModalOptionsChildrenType = {
  children: React.ReactNode;
  additionalProps?: any;
};

export const modalOptions = {
  preventScroll: true,
  focusTrapOptions: {
    clickOutsideDeactivates: true,
  },
  components: {
    Modal: ({ children, additionalProps }: ModalOptionsChildrenType) => {
      const [down, setDown] = useState(false);
      return (
        <div
          style={{
            padding: '2rem 1rem 2rem',
            backgroundColor: '#fff',
            borderRadius: '1rem 1rem 0 0',
            width: '100vw',
            maxWidth: '400px',
            bottom: 0,
          }}
          className={!down ? styles.modal_animation_wrap : styles.modal_animation_down}
        >
          {children}
          {additionalProps?.closeButton && (
            <div
              style={{
                position: 'absolute',
                bottom: '1rem',
                right: '1rem',
                cursor: 'pointer',
              }}
              onClick={() => {
                setDown(true);
                sleep(250).then(() => {
                  additionalProps.closeButton();
                });
              }}
            >
              ✕
            </div>
          )}
        </div>
      );
    },
    Overlay: () => {
      return (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          }}
        />
      );
    },
    Wrapper: ({ children }: ModalOptionsChildrenType) => {
      return (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-end',
            zIndex: 1000,
          }}
        >
          {children}
        </div>
      );
    },
  },
};
