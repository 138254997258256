import { useEffect, useState } from 'react';
import { useMedia } from 'react-use';

const useIsPc = (): boolean => {
  const listenIsPc = useMedia('(min-width: 576px)', false);
  const [isPc, setIsPc] = useState(listenIsPc);
  useEffect(() => {
    setIsPc(listenIsPc);
  }, [listenIsPc]);

  return isPc;
};

const useIsSp = (): boolean => {
  const listenIsSp = useMedia('(max-width: 575px)', false);
  const [isSp, setIsSp] = useState(listenIsSp);
  useEffect(() => {
    setIsSp(listenIsSp);
  }, [listenIsSp]);

  return isSp;
};

export { useIsPc, useIsSp };
