import { useEffect } from 'react';
import { SupabaseClient } from '@supabase/supabase-js';
// ここはfilePathが書き換わる可能性がある
import { AppRouterInstance } from 'next/dist/shared/lib/app-router-context.shared-runtime';
import { usePathname } from 'next/navigation';
import { useAuth } from '@/hooks/useAuth';

const useAuthStateChange = (router: AppRouterInstance, supabase: SupabaseClient) => {
  const { setUser } = useAuth();
  const pathname = usePathname();

  useEffect(() => {
    supabase.auth.onAuthStateChange((event) => {
      if (event === 'SIGNED_OUT') {
        if (
          !pathname.includes('/login/forgetpassword') &&
          !pathname.includes('/login/reset-password')
        ) {
          router.push('/login');
          setUser(null);
        }
      }
    });
  }, [pathname, router, setUser, supabase.auth]);
};

export default useAuthStateChange;
