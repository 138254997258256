import { useAtom } from 'jotai';
import { drawerAtom } from '@/hooks/state/drawerState';

export const useDrawer = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useAtom(drawerAtom);

  const toggleDrawerOpen = (open?: boolean) => {
    if (open !== undefined) {
      setIsDrawerOpen(open);
    } else {
      setIsDrawerOpen((prev) => !prev);
    }
  };

  return { isDrawerOpen, toggleDrawerOpen };
};
