import { useEffect, useCallback } from 'react';
import { useAtom } from 'jotai';
import { useRouter, usePathname } from 'next/navigation';
import { getAuth } from '@/api/endpoints/auth';
import { fetchProfile } from '@/api/endpoints/profile';
import { authAtom } from '@/hooks/state/authState';
import { profileAtom } from '@/hooks/state/profileState';

export const useAuth = () => {
  const [user, setUser] = useAtom(authAtom);
  const [profile, setProfile] = useAtom(profileAtom);
  const router = useRouter();
  const pathname = usePathname();

  const fetchAuth = useCallback(async () => {
    try {
      if (!user) {
        const data = await getAuth();
        setUser(data.user);
      }
      if (!profile) {
        const profileData = await fetchProfile();
        setProfile(profileData);
      }
    } catch (error) {
      // 現在のurlが /forgetpassword、または reset-passwordを含むならば、エラーを無視する
      if (
        error &&
        !pathname.includes('/login/forgetpassword') &&
        !pathname.includes('/login/reset-password')
      ) {
        router.push('/login');
      }
    }
  }, [user, profile, setUser, setProfile, pathname, router]);

  useEffect(() => {
    if (user === null) {
      fetchAuth();
    }
  }, [user, fetchAuth]);

  return { auth: user, setUser, profile };
};
