/**
 * 処理を`ms`秒だけ一時停止する
 */
export const sleep = (ms: number) => {
  return new Promise((r) => setTimeout(r, ms));
};

/**
 * stringのbooleanをbooleanに変換する
 * @param str
 * @returns boolean
 */
export const convertStringToBoolean = (str: string): boolean => {
  return str === 'true' ? true : false;
};

/**
 * 引数で与えられらメールアドレスから、@以降の文字列を削除して返却する関数
 */
export const removeAfterAtmark = (email: string): string => {
  return email.split('@')[0];
};

/**
 * [[ で始まり、]]で終わる区間を削除して返却する関数
 */
export const removeBracket = (str: string): string => {
  return str.replace(/\[\[.*?\]\]/g, '');
};
