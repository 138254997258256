'use client';
import React from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import LogoutIcon from '@mui/icons-material/Logout';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar,
  IconButton,
  Toolbar,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
} from '@mui/material';
import clsx from 'clsx';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import Logo from '@/assets/besmart_logo_typo.svg';
import Avatar from '@/components/common/Avatar';
import { TenantSelector } from '@/components/common/TenantSelector';
import { useIsPc } from '@/hooks/media-query';
import { useAuth } from '@/hooks/useAuth';
import { useDrawer } from '@/hooks/useDrawer';
import { getAppVersion, getDeployDateTime } from '@/utils/application';
import supabase from '@/utils/supabase';
import styles from './Header.module.scss';

const drawerWidth = '240px';

export default function Header() {
  const { auth, profile } = useAuth();
  const { isDrawerOpen, toggleDrawerOpen } = useDrawer();
  const isPc = useIsPc();
  const router = useRouter();

  // ログアウトの処理を追加
  const doLogout = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) throw new Error(error.message);
    router.push(`/login`);
  };

  return (
    <div>
      <AppBar
        position="fixed"
        className={styles.header_wrap}
        style={{
          boxShadow: 'none',
          zIndex: 100,
          width: isDrawerOpen && isPc ? 'calc(100% - 240px)' : '100%',
        }}
      >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => {
              toggleDrawerOpen();
            }}
          >
            <MenuIcon />
          </IconButton>
          <div className="text-lg font-bold flex-1">
            <Link href="/">
              <Logo className={styles.logo} />
            </Link>
          </div>
          {auth ? (
            <Link href="/profile">
              <Avatar
                style={{ width: '32px', height: '32px' }}
                src={profile?.profileUrl ? profile?.profileUrl : undefined}
                alt={profile?.name ? profile?.name : ''}
              />
            </Link>
          ) : (
            <Link href="/login">
              <Button color="inherit">Login</Button>
            </Link>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        variant="persistent"
        anchor="left"
        open={isDrawerOpen}
        style={{ width: drawerWidth }}
        className={styles.drawer_wrap}
        transitionDuration={300}
      >
        <div className={styles.drawer_top}>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => {
              toggleDrawerOpen();
            }}
          >
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <List>
          <Divider />
          <div className={styles.tenant_selector_wrap}>
            <TenantSelector />
          </div>
          <Divider />
          <ListItem key="ManageAccountsIcon" disablePadding>
            <Link href="/management" className={styles.drawer_link_wrap}>
              <ListItemButton>
                <ListItemIcon>
                  <ManageAccountsIcon />
                </ListItemIcon>
                <ListItemText primary="管理メニュー" />
              </ListItemButton>
            </Link>
          </ListItem>
          <ListItem key="Logout" disablePadding>
            <ListItemButton onClick={doLogout}>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="ログアウト" />
            </ListItemButton>
          </ListItem>
        </List>
        <div className={clsx(styles.version_wrap, 'p-2')}>
          {getAppVersion()}
          <br />
          {getDeployDateTime()}
        </div>
      </Drawer>
    </div>
  );
}
