import React from 'react';
import { Avatar as MUIAvatar, Tooltip } from '@mui/material';
import { lightBlue } from '@mui/material/colors';
import clsx from 'clsx';

export default function Avatar(props: React.ComponentProps<typeof MUIAvatar>) {
  const { children, alt, src } = props;
  return (
    <Tooltip title={alt} disableFocusListener enterTouchDelay={0}>
      <MUIAvatar sx={{ bgcolor: lightBlue[500] }} {...props} className={clsx(props.className)}>
        {!src ? (alt ? alt.slice(0, 1) : children) : null}
      </MUIAvatar>
    </Tooltip>
  );
}
