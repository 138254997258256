/**
 * アプリバージョンを返却する
 * @returns string
 */
export const getAppVersion = () => {
  return process.env.NEXT_PUBLIC_VERSION || 'v0.0.1';
};

/**
 * デプロイ日付を返却する
 */
export const getDeployDateTime = () => {
  return process.env.NEXT_PUBLIC_DEPLOY_DATETIME || '-';
};
