import { createClientComponentClient } from '@supabase/auth-helpers-nextjs';
import { createClient } from '@supabase/supabase-js';
import { Database } from '@/lib/database.types';

const supabase = createClientComponentClient<Database>();

export default supabase;

export const basicClient = createClient<Database>(
  process.env.NEXT_PUBLIC_SUPABASE_URL!,
  process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY!
);

export const SUPABASE_PRIVATE_BUCKET_NAME = 'susiprivate';
export const SUPABASE_PUBLIC_BUCKET_NAME = 'susi';
