import { ReactNode } from 'react';
import { useRouter } from 'next/navigation';
import useAuthStateChange from '@/hooks/useAuthStateChange';
import supabase from '@/utils/supabase';

// LoginProviderの型を定義する
type LogintProviderProps = {
  children: ReactNode;
};
const LoginProvider = ({ children }: LogintProviderProps) => {
  const router = useRouter();
  useAuthStateChange(router, supabase);

  return <>{children}</>;
};

export default LoginProvider;
