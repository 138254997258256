'use client';

import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { jaJP } from '@mui/x-date-pickers/locales';
import clsx from 'clsx';
import ja from 'date-fns/locale/ja';
import { Provider as JotaiProvider } from 'jotai';
import { Inter } from 'next/font/google';
import { ModalProvider } from 'react-hooks-use-modal';
import ThemeRegistry from '@/app/ThemeRegistry';
import { modalOptions } from '@/components/common/options/modalOptions';
import Footer from '@/components/layouts/Footer';
import Header from '@/components/layouts/Header';
import './globals.scss';
import LoginProvider from '@/components/providers/LoginProvider';
import { useIsPc } from '@/hooks/media-query';
import { useDrawer } from '@/hooks/useDrawer';
import styles from './layout.module.scss';

// import type { Metadata } from 'next';

const inter = Inter({ subsets: ['latin'] });

const japaneseLocale = jaJP.components.MuiLocalizationProvider.defaultProps.localeText;

export default function RootLayout({ children }: { children: React.ReactNode }) {
  return (
    <JotaiProvider>
      <InnerRootLayout>{children}</InnerRootLayout>
    </JotaiProvider>
  );
}

const InnerRootLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { isDrawerOpen } = useDrawer();
  const isPc = useIsPc();

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={ja}
      localeText={japaneseLocale}
    >
      <html lang="ja">
        <body className={inter.className}>
          <ThemeRegistry options={{ key: 'mui' }}>
            <LoginProvider>
              <ModalProvider {...modalOptions}>
                <Header />
                <div
                  id="root"
                  className={clsx(styles.wrap, {
                    [styles.drawer_open]: isDrawerOpen && isPc,
                    [styles.drawer_closed]: !isDrawerOpen,
                  })}
                >
                  {children}
                </div>
                <Footer />
              </ModalProvider>
            </LoginProvider>
          </ThemeRegistry>
        </body>
      </html>
    </LocalizationProvider>
  );
};
