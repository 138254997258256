import { useEffect, useState } from 'react';
import fetchAPI from '@/api';

type FooterBudgeType = {
  unreadMentionCount: number;
};

const initialState: FooterBudgeType = {
  unreadMentionCount: 0,
};

export function useFooterBudge(tenantId?: string) {
  const [budge, setBudge] = useState<FooterBudgeType>(initialState);
  const [error, setError] = useState<Error | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchFooterBudge = async function () {
    if (!tenantId) return;
    setIsLoading(true);
    try {
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      };
      const response = await fetchAPI(`mention/unread/${tenantId}`, requestOptions);

      const unreadMentionCount: number = response;
      setBudge({ unreadMentionCount });
    } catch (err: unknown) {
      if (err instanceof Error) {
        setError(err);
      } else {
        setError(new Error('An unexpected error occurred'));
      }
      setBudge(initialState);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (tenantId) {
      fetchFooterBudge();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantId]);

  const clearUnreadMention = async function () {
    if (!tenantId) return;
    setIsLoading(true);
    try {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      };
      await fetchAPI(`mention/unread/${tenantId}`, requestOptions);
      setBudge(initialState);
    } catch (err: unknown) {
      if (err instanceof Error) {
        setError(err);
      } else {
        setError(new Error('An unexpected error occurred'));
      }
    } finally {
      setIsLoading(false);
    }
  };

  return { budge, fetchFooterBudge, clearUnreadMention, error, isLoading };
}
