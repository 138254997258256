import React, { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Button, Menu, MenuItem } from '@mui/material';
import { Tenant } from '@prisma/client';
import { useRouter } from 'next/navigation';
import { useTenant } from '@/hooks/useTenant';
import styles from './TenantSelector.module.scss';

export function TenantSelector() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { currentTenant, tenants, setCurrentTenant } = useTenant();
  const router = useRouter();

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleTenantClick = (tenant: Tenant) => {
    setCurrentTenant(tenant);
    handleClose();
    router.push('/');
  };

  return (
    <div>
      <Button
        id="current-tenant-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <div className={styles.tenant_name_wrap}>
          <div className={styles.tenant_name}>{currentTenant?.name}</div>
          <KeyboardArrowDownIcon
            style={{
              width: '20px',
              height: '20px',
            }}
          />
        </div>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'current-tenant-button',
        }}
      >
        {tenants?.map((tenant) => {
          return (
            <MenuItem
              key={tenant.id}
              onClick={() => {
                handleTenantClick(tenant);
              }}
            >
              {tenant.name}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
}
